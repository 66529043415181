
body {
  font-family: proxima-nova,sans-serif;
  font-size: 16;
  min-height: 100vh;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App {
  min-width: 100%;
  min-height: 100%;
  text-align: center;
}

.inset {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: center;*/

  padding: calc(30px + 8vh) 30px;
  margin-bottom: 0px;
  
  position: relative;
  min-height: calc(100vh - 68px);
  min-width: 100%;

  background: #F8F7F9;
  -moz-box-shadow:    0px 2px 14px 0px rgba(70,56,196,.11) inset;
  -webkit-box-shadow: 0px 2px 14px 0px rgba(70,56,196,.11) inset;
  box-shadow:         0px 2px 14px 0px rgba(70,56,196,.11) inset;
}

.center {
  text-align: center;
}
.wrapper {
  position: relative;
}

.App-link {
  color: #61dafb;
}

.logo {
  position: absolute;
  bottom: 16px;
  left: 16px;
}

h2 {
  font-size: 2em; 
}
h2.recipient,
h2.settings {
  color: #6B5BFF;
}
h2.sender {
  color: #9C70FD;
}

input {
  text-transform: uppercase;
}


.sizeField {
  margin: 0 1em 1em 1em;
}

.ui.button {
  font-family: proxima-nova,sans-serif;
  transition: .2s;
}

.ui.button.clear {
  background: none;
  float: right;
  padding: 8px;
}
.ui.button.clear svg path {
  fill: rgba(65,69,80,.5);
}
.ui.button.clear:hover svg path {
  fill: rgba(107,91,255,1);
}

.ui.button.download, 
.ui.button.save {
  font-size: 1.8em;
  border-radius: 50px;
  padding: 0.6em 2em;
  margin: 150px auto 10px auto;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#9c70fd+0,6b5bff+100 */
  background: #9c70fd; /* Old browsers */
  background: -moz-linear-gradient(-45deg,  #9c70fd 0%, #6b5bff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg,  #9c70fd 0%,#6b5bff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg,  #9c70fd 0%,#6b5bff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9c70fd', endColorstr='#6b5bff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  box-shadow: 0px 12px 9px -10px rgba(0,0,0,0.4), 0px 12px 44px 0 rgba(154,109,255,0.4);
  transition: .5s;
  height: 55px;
}
.ui.button.save {
  margin: 20px auto;
}
.ui.button.download:hover,
.ui.button.save:hover {
  transform: scale(1.1);
  box-shadow: 0px 12px 9px -10px rgba(0,0,0,0.6), 0px 12px 44px 0 rgba(154,109,255,0.9);
}

#logo {
  fill: url(#gradient-diagonal) #9d70fe;
}

.my-svg-alternate {
  display: none;
}
.no-svg .my-svg-alternate {
  display: block;
  width: 100px;
  height: 100px;
  background-image: url(/envelope-logo.png);
}

.dim {
  color: #9182a1;
  font-style: italic;
  font-size: 1.2em
}


.ui.basic.label {
  line-height: 2.1em;
  color: rgba(0,0,0,0.5);
}
.ui.basic.label.mini-label {
  position: absolute;
  top: -27px;
  left: 0;
  background: none;
  border: none;
  line-height: 1em;
  text-align: center;
  width: 100%;
}
.ui.labeled.input:not([class*="corner labeled"]) .label:first-child+input#height:focus {
    border-left-color: #85b7d9 !important;
}
.ui.labeled.input:not([class*="corner labeled"]) .label:first-child+input#width:focus {
    border-left-color: #85b7d9 !important;
}
input#height, input#width {
    border-radius: 50px;
    width: 60px !important;
    text-align: center;
    font-size: 1.3em !important;
    padding: .3em !important;
    border-left-color: rgba(34,36,38,.15);
    border-radius: 22px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    padding: .3em 0 .3em .6em !important;
  }

.ui.basic.horizontal.label {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: rgba(0,0,0,0.3);
    padding-left: 0;
    padding-right: 0;
    min-width: 1.5em;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    line-height: 0.9;
  }

.ui.labeled.input:not([class*="corner labeled"]) .label:first-child+input {
    /*background: none;*/
    z-index: 1;
}

.help-height,
.help-width {
  display: none;
  color: red;
  position: absolute;
  bottom: -26px;
  left: -6px;
  width: 100%;
}

#height:focus + .help-height,
#width:focus + .help-width {
  display: block;
}

.sizes {
  width: 100%;
  display: flex;
  text-align: right;
  justify-content: flex-end;
  position: absolute;
}
.sizes .ui.basic.button:hover {
  box-shadow: 0 0 0 1px rgba(34,36,38,.35) inset, 0 0 0 0 rgba(34,36,38,.15) inset, 0px 7px 8px -5px rgba(0,0,0,0.3), 0px 10px 34px 0 rgba(154,109,255,0.5);
  z-index: 200;
  position: relative;
}

@media only screen and (min-width: 1200px) {
  .ui.form.center {
    height: 100%;
    position: absolute;
    width: 100%;
    padding-bottom: 0;
  }
  .fields {
    width: 100%;
    height: 100%;
  }
  .field.height-input {
    position: absolute;
    left: -8vw;
    top: 48%;
  }
  .field.width-input {
    position: absolute;
    left: calc(50% - 57px);
    bottom: -80px;
  }

  .sizes {
    width: 100%;
    top: -50px;
    position: relative;
    display: flex;
    justify-content: center;
    left: 7px;
  }
  
  .help-height,
  .help-width {
    bottom: -26px;
    left: -6px;
    right: 0;
    font-size: 0.9em;
  }

}







.envelope-size {
  padding-bottom: 30px;
}
.envelope-sample {
  max-width: 1000px;
  height: auto;
  background: #FFFFFF;
  box-shadow: 0 10px 20px rgba(0,0,0,0.2);
  transition: .3s;
  font-family: Helvetica, sans-serif;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: bounce 1s .5s;
}
 
@media only screen and (max-width: 1200px) {
  .envelope-sample {
    width: 90vw;
  }
  .sender-block {
    font-size: calc( 100vw / 61);
  }
  .recipient-block {
    font-size: calc( 100vw / 61);
  }
}

.envelope-sample .aspect-ratio {
    content: '';
    width: 912px;
    padding-bottom: 45%;
    display: flex;
    justify-content: center;
    max-width: 100%;
    transition: .3s;
}

@keyframes bounce {
  0% { transform: scale(1); opacity: 1 }
  50% { transform: scale(1.1); opacity: .7; }
  60% { transform: scale(0.9); opacity: 1 }
  80% { transform: scale(1.1) }
  100% { transform: scale(1) }
}

.sender-block,
.recipient-block {
  border-radius: 0.35em;
  border: 1px solid rgba(65,69,80,.0);
  padding: .5em;
  transition: .3s;
  text-align: left;
}

.sender-block:hover,
.recipient-block:hover {
  border: 1px solid rgba(65,69,80,.25);
  box-shadow: 0 8px 20px rgba(65,69,80,.09);
  cursor: pointer;
}

.sender-block {
  line-height: 1.2em;
  position: absolute;
  top: 4%;
  left: 2%;
  /*min-width: 200px;
  min-height: 150px;*/
}

.sender-name {
  font-weight: bold;
}

.recipient-block {
  line-height: 1.5em;
  position: absolute;
  top: 50%;
  /*min-width: 200px;
  min-height: 150px;*/
  transform: translateY(-50%);
}

.recipient-name {
  font-weight: bold;
  font-size: 1.25em;
}






.fineprint {
  color: rgba(65,69,80,.5);
  padding: 5px 5px 70px;
  text-align: right;
  font-size: 11px;
}

.preview-watermark {
  position: absolute;
  bottom: 50px;
  right: 15px;
  color: #f3f3f3;
  font-size: 96px;
  font-weight: bold;
}
